import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Logo from "./Logo";
import { Link } from "gatsby";
const CampaignLogo = ({ partnerLogo }) => {
    return (
        <>
            <div className="logo-header">
                <div className="site-logo">
                    <div to="/" className="logo-inner">
                        <div className="ring">
                            <Logo />
                        </div>
                        <svg
                            className="logo"
                            xmlns="http://www.w3.org/2000/svg"
                            width="100"
                            height="32.338"
                            viewBox="0 0 100 32.338"
                        >
                            <path
                                d="M16.473-85.474c5.047,0,10.92-2.756,10.92-9.5,0-5.937-4.22-7.337-9.542-9.415-3.542-1.378-5.217-2.184-5.2-4.114.021-1.951,1.59-3.1,3.711-3.1,1.93,0,4.156.933,6.722,3.033l3.52-4.6a16,16,0,0,0-10.391-3.881c-5.535.064-9.9,3.86-9.9,8.822,0,5.81,4.665,7.973,9.331,9.606,3.732,1.294,5.365,2.1,5.365,4.368,0,1.908-1.484,3.265-4.432,3.265A10.555,10.555,0,0,1,8.9-94.846L5.234-90.329a15.553,15.553,0,0,0,11.238,4.856ZM49.3-96.628c0-6.213-4.177-10.772-10.412-10.772-6.022,0-10.518,4.5-10.518,10.942a10.515,10.515,0,0,0,10.92,10.984,11.288,11.288,0,0,0,9.161-4.008l-3.69-3.435a6.282,6.282,0,0,1-5.068,2.4,5.341,5.341,0,0,1-5.407-4.071H49.154a10.808,10.808,0,0,0,.148-2.036ZM39.082-102.4a4.5,4.5,0,0,1,4.581,3.711H34.311A4.671,4.671,0,0,1,39.082-102.4Zm27.55-15.415v13.168h-.106a8.216,8.216,0,0,0-6.4-2.757c-5.683,0-9.988,4.58-9.988,11.027,0,6.552,4.283,10.9,10.157,10.9A7.852,7.852,0,0,0,66.781-88.4h.127v2.566h5.407v-31.977Zm-5.3,27.248c-3.181,0-5.429-2.354-5.429-5.789,0-3.626,2.4-5.937,5.45-5.937a5.6,5.6,0,0,1,5.577,5.937,5.511,5.511,0,0,1-5.6,5.789Zm17.307-19.466a3.5,3.5,0,0,0,3.52-3.478,3.512,3.512,0,0,0-3.52-3.456,3.5,3.5,0,0,0-3.52,3.456A3.489,3.489,0,0,0,78.639-110.029ZM75.8-85.835H81.48V-107.04H75.8ZM97.537-107.4a8.838,8.838,0,0,0-6.807,3.1h-.064v-2.735H84.983v21.205h5.683V-96.5c0-3.775,1.93-5.789,4.814-5.789,2.63,0,4.071,1.251,4.071,5.3v11.175h5.683v-12.6c0-6.531-3.16-8.991-7.7-8.991Z"
                                transform="translate(-5.234 117.812)"
                            />
                        </svg>
                    </div>
                </div>
                <div className="partner-logo">
                    {partnerLogo?.gatsbyImageData ? (
                        <GatsbyImage image={partnerLogo?.gatsbyImageData} alt={partnerLogo.title} />
                    ) : (
                        <img src={partnerLogo?.file.url} alt={partnerLogo?.title} />
                    )}
                </div>
            </div>
        </>
    );
};

export default CampaignLogo;
