import React from "react";
import { useState, useRef } from "react";
import { navigate } from "gatsby";
import Spinner from "./Spinner";
import axios from "axios";
const Form = () => {
    const formAPIURL = `${process.env.GATSBY_CONTACT_FORM_URL}`;

    const [formSubmitError, setSubmitError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const formEl = useRef(null);

    const [stepForm, setFormState] = useState({
        name: "",
        email: "",
        message: "",
        // phone: "",
    });
    const [isSubmitted, setSubmit] = useState(false);
    const [validationErrors, setValidationError] = useState({
        nameError: "",
        emailError: "",
        // phoneError: "",
    });

    function inputsHandler(e) {
        setFormState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    function formValidator() {
        let nameError = "";
        let emailError = "";
        // let phoneError = "";

        if (!/^[a-zA-Z ]+$/.test(stepForm.name)) nameError = "Invalid Name";
        if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                stepForm.email
            )
        )
            emailError = "Invalid Email";
        // if (!/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(stepForm.phone))
        //     phoneError = "Invalid Phone number";
        if (!stepForm.name) nameError = "Name is required";
        if (!stepForm.email) emailError = "Email is required";

        if (nameError || emailError) {
            setValidationError({ nameError, emailError });
            return false;
        }
        return true;
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const isValid = formValidator();
        if (isValid) {
            setLoading(true);
            // reset validation errors
            setValidationError({
                nameError: "",
                emailError: "",
                // phoneError: "",
            });
            setSubmitError("");
            const formdata = stepForm;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setSubmit(false);
                    setFormState({
                        name: "",
                        email: "",
                        // phone: "",
                        message: "",
                    });
                    setLoading(false);
                    navigate("/contact/thank-you/");
                })
                .catch((err) => {
                    setSubmit(false);
                    setLoading(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
    }

    return (
        <form
            className="landing-form"
            onSubmit={(e) => handleFormSubmit(e)}
            ref={formEl}
            noValidate
            autoComplete="off"
        >
            <div className="text-p1 header text-clr-primary gap-05x text-fw-medium">
                Book a free consultation to accelerate your data journey
            </div>
            <div className="input-row-wrap gap-2x">
                <div className="input-row">
                    <div className="mat-group">
                        <input
                            type="text"
                            className={`${
                                validationErrors.nameError && isSubmitted ? "error" : ""
                            }`}
                            name="name"
                            required
                            autoComplete="off"
                            onChange={(e) => inputsHandler(e)}
                            value={stepForm.name}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label htmlFor="name">Name*</label>
                        <div className="error-resp">{validationErrors.nameError}</div>
                    </div>

                    <div className="mat-group">
                        <input
                            type="text"
                            className={`${
                                validationErrors.emailError && isSubmitted ? "error" : ""
                            }`}
                            name="email"
                            onChange={(e) => inputsHandler(e)}
                            required
                            autoComplete="off"
                            value={stepForm.email}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label htmlFor="email">Business Email*</label>
                        <div className="error-resp">{validationErrors.emailError}</div>
                    </div>
                    {/* <div className="mat-group">
                        <input
                            type="text"
                            className={`${
                                validationErrors.phoneError && isSubmitted ? "error" : ""
                            }`}
                            name="phone"
                            onChange={(e) => inputsHandler(e)}
                            required
                            autoComplete="off"
                            value={stepForm.phone}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label htmlFor="email">Phone number</label>
                        <div className="error-resp">{validationErrors.phoneError}</div>
                    </div> */}

                    <div className="mat-group">
                        <textarea
                            rows={5}
                            type="text"
                            name="message"
                            required
                            onChange={(e) => inputsHandler(e)}
                            value={stepForm.message}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label htmlFor="name">Message</label>
                        <div className="error-resp"></div>
                    </div>
                </div>
            </div>
            <button className="sdn-cta-btn next" type="submit">
                <span>Talk to our Tableau experts</span>
                <Spinner loading={isLoading} />
            </button>
        </form>
    );
};

export default Form;
