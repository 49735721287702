import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import { useEffect } from "react";
import TableauGif from "../images/gif/tableau.gif";

const TextWithImage = ({ title, description, image, gif }) => {
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        },
        renderMark: {
            [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
        },
    };
    useEffect(() => {
        document.querySelector(".scroll-btn").addEventListener("click", function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute("href")).scrollIntoView({
                behavior: "smooth",
            });
        });
    }, []);
    return (
        <div className="text-with-image">
            <div>
                <h2 className="text-h2 text-fw-medium gap-1x">{title}</h2>
                <div className="text-p3 text-fw-medium gap-3x text-clr-secondary ">
                    {renderRichText(description, options)}
                </div>
                <a href="#campaign-contact" className="campaign-cta-btn scroll-btn">
                    Request a demo
                </a>
            </div>
            <div className="text-with-image__image">
                {gif ? (
                    <img src={TableauGif} alt="tableau-gif" />
                ) : (
                    <GatsbyImage image={image} alt="image" />
                )}
            </div>
        </div>
    );
};

export default TextWithImage;
