import React from "react";
import CampaignLogo from "../components/CampaignLogo";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import TextWithImage from "../components/TextWithImage";
import Testimonials from "../components/Testimonials";
import ServiceFacilities from "../components/ServiceFacilities";
import "../styles/main.scss";
import EmailBlockV2 from "../components/EmailBlockV2";
import CampaignFooter from "../components/CampaignFooter";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import Form from "../components/Form";
import Seo from "../components/seo";
import DashboardFaq from "../components/DashboardFaq";

const GlobalCampaign = ({ data, location }) => {
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul>{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <li className="text-p3  text-clr-secondary text-fw-regular">{children}</li>
            ),
        },
    };
    let usTableau = location.pathname.includes("hire-tableau-developer");
    let lpTableau = location.pathname.includes("tableau");

    return (
        <>
            <Seo title="Tableau - Sedin" />
            <section className="global-campaign-hero">
                <div className="global-campaign-hero__container">
                    <CampaignLogo partnerLogo={data.pageData.logo} />
                    <div className="global-campaign-hero__wrapper">
                        <div className="global-campaign-hero__grid">
                            <div>
                                <div className="global-campaign-hero__content">
                                    <div className="global-campaign-hero__text">
                                        <h1 className="text-h1 gap-1x text-fw-medium text-clr-primary">
                                            {data.pageData.title}
                                        </h1>
                                        {/* <p className="text-p3 text-clr-secondary text-fw-regular">
                                        {data.pageData.description}
                                    </p> */}
                                        {/* <ul>
                                        <li className="text-p3 gap-05x text-clr-secondary text-fw-regular">
                                            Tableau dashboards are a powerful window to analyse
                                            large and micro data from across your organization
                                        </li>
                                        <li className="text-p3 gap-05x text-clr-secondary text-fw-regular">
                                            Cut down on the time you spend on manual reports,
                                            discover accurate predictions on future trends
                                        </li>
                                    </ul> */}
                                        {renderRichText(data.pageData.description, options)}
                                    </div>
                                    {/* <EmailBlockV2 /> */}
                                </div>
                            </div>
                            {/* <div>
                                <GatsbyImage
                                    image={data.pageData.bannerImage.gatsbyImageData}
                                    alt={data.pageData.bannerImage.title}
                                />
                            </div> */}
                            <Form />
                        </div>
                        <div className="global-campaign-clients__grid">
                            <p className="text-p3 text-clr-secondary text-fw-medium">Trusted by</p>
                            <div className="global-campaign-clients__grid-logo">
                                {data.pageData.clients.map((el, i) => (
                                    <GatsbyImage
                                        key={i}
                                        image={el.gatsbyImageData}
                                        alt={el.title}
                                        loading="lazy"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="global-campaign-clients">
                <div className="container">
                    <div className="global-campaign-clients__grid">
                        <p className="text-p3 text-clr-secondary text-fw-medium">Trusted by</p>
                        <div className="global-campaign-clients__grid-logo">
                            {data.pageData.clients.map((el, i) => (
                                <GatsbyImage key={i} image={el.gatsbyImageData} alt={el.title} />
                            ))}
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className="container container--mid-v2">
                    <ServiceFacilities
                        facilities={data.pageData.serviceFacilities}
                        title="Maximize your business potential with Tableau services"
                        process
                    />
                </div>
            </section>
            {!usTableau && (
                <section>
                    <div className="container container--mid-v2">
                        <TextWithImage
                            title={data.pageData.serviceInfoHeader}
                            description={data.pageData.serviceIntroDescription}
                            image={data.pageData.serviceInfoImage.gatsbyImageData}
                            gif
                        />
                    </div>
                </section>
            )}
            {lpTableau != usTableau && (
                <section className="dashboard-faq">
                    <div className="container container--mid-v2">
                        <h2 className="text-h2  text-fw-medium">{data.pageData.faqHeader}</h2>
                        <DashboardFaq data={data.pageData.faq} image={data.pageData.faqImage} />
                    </div>
                </section>
            )}
            {/* <section className="partner-intro">
                <div className="container container--mid-v2">
                    <h1 className="text-h2 gap-1x text-clr-primary text-fw-medium ">
                        {data.pageData.serviceIntroHeader}
                    </h1>
                    <p className="text-p2 gap-2x text-clr-secondary text-fw-regular">
                        {data.pageData.serviceIntroDescription.serviceIntroDescription}
                    </p>
                    <GatsbyImage
                        image={data.pageData.serviceIntroImage.gatsbyImageData}
                        alt={data.pageData.serviceIntroImage.title}
                    />
                </div>
            </section> */}
            <section>
                <div className="container container--mid-v2">
                    <Testimonials testimonialData={data.pageData.testimonial} />
                    {/* <SingleTestimonial testimonial={data.pageData.testimonial} /> */}
                </div>
            </section>
            {/* <section className="container--mid-v2">
                <div
                    style={{
                        backgroundColor: `#F8F9FA`,
                    }}
                >
                    <div className="container ">
                        <Statistics stats={data.pageData.statistics} />
                    </div>
                </div>
            </section> */}
            <section className="service-process">
                <div className="container container--mid-v2">
                    {/* <h2 className="service-process__text text-h2 text-fw-medium ">Our process</h2> */}
                    {/* <ServiceProcess data={data.pageData.serviceProcess} /> */}
                    {/* <StaticImage
                        src="../images/our-process.png"
                        placeholder="blurred"
                        alt="tableau-process"
                    /> */}
                    <ServiceFacilities
                        facilities={data.pageData.serviceProcess}
                        title="Our process"
                        process
                    />
                </div>
            </section>

            <section className="global-campaign-contact" id="campaign-contact">
                <div className="container ">
                    <div className="global-campaign-contact__text">
                        <h2 className="text-h2  text-fw-medium">
                            {data.pageData.callToAction.header}
                        </h2>
                        <EmailBlockV2
                            FooterCallToAction
                            background="#f8f9fa"
                            title="Talk to our experts"
                        />
                    </div>
                </div>
            </section>

            <CampaignFooter tableau GlobalCampaign />
        </>
    );
};
export const query = graphql`
    query globalCampaignPageQuery($id: String) {
        pageData: contentfulGlobalCampaign(id: { eq: $id }) {
            id
            title
            description {
                raw
            }
            location
            slug
            logo {
                title
                gatsbyImageData(
                    sizes: "100"
                    quality: 100
                    placeholder: BLURRED
                    layout: CONSTRAINED
                )
                file {
                    url
                }
            }
            statistics {
                name
                value
            }

            testimonial {
                id
                personDesignation
                personName
                profileImage {
                    gatsbyImageData(
                        quality: 100
                        sizes: "250"
                        layout: CONSTRAINED
                        placeholder: BLURRED
                    )
                    title
                }
                testimonialType
                testimonialStatement {
                    testimonialStatement
                }
            }
            bannerImage {
                title
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            clients {
                title
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            serviceInfoHeader
            serviceIntroHeader
            serviceIntroDescription {
                raw
            }
            serviceFacilities {
                title
                description
                icon {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                    file {
                        url
                    }
                }
            }
            serviceIntroImage {
                title
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            serviceInfoImage {
                title
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            callToAction {
                ctaText
                ctaSlug
                header
            }
            serviceProcess {
                title
                processDescription {
                    processDescription
                }
                icon {
                    title
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
            faqHeader
            faq {
                title
                description {
                    description
                }
            }
            faqImage {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                title
                file {
                    url
                }
            }
        }
    }
`;
export default GlobalCampaign;
